import React from 'react';

import Comma from './parts/Comma';

import CssClassNames from '../../scss/CssClassNames';
import styles from './styles.module.scss';
const { className } = new CssClassNames(styles);

const Loader = ({ data }) => (
    <div className={styles.loader}>
        <Comma className={styles.comma} spinner={true} />
    </div>
)

export default Loader;